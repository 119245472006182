import * as React from "react";
import {
  Box,
  Flex
} from "@chakra-ui/react";
import {
  keyframes
} from "@chakra-ui/react";

import {
  Title
} from "./Templates";


interface Props {
  itemWidth: object
  template: string
  nodes: any[]
}


const ticker = ( scrollLength: number ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;


export const Ticker: React.FC<Props> = ( props ) => {
  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'title':
        return (
          <Title
            key={ _index }
            heading={ node.heading }
            width={ props.itemWidth }
          />
        );
      default:
        return null;
    }
  })

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
    >
      <Box
        w="100%"
        h="100%"
        overflow="hidden"
        boxSizing="content-box"
      >
        <Flex
          boxSizing="content-box"
          animation={{
            base: `${ ticker( props.itemWidth['base'] * props.nodes.length ) } 60s linear infinite`,
            sm: `${ ticker( props.itemWidth['sm'] * props.nodes.length ) } 60s linear infinite`,
            md: `${ ticker( props.itemWidth['md'] * props.nodes.length ) } 60s linear infinite`,
            lg: `${ ticker( props.itemWidth['lg'] * props.nodes.length ) } 60s linear infinite`,
            xl: `${ ticker( props.itemWidth['xl'] * props.nodes.length ) } 60s linear infinite`,
          }}
        >
          { nodeList }
          { nodeList }
        </Flex>
      </Box>
    </Flex>
  )
}
