import * as React from "react";
import {
  Flex,
  Heading
} from "@chakra-ui/react";


interface Props {
  heading: string
  width: object | string
}


export const Title: React.FC<Props> = ( props ) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minW={ props.width }
      w={ props.width }
    >
      <Heading
        as="h1"
        m="0"
        fontSize={{
          base: '70px',
          sm: '100px',
          md: '150px',
          lg: '210px',
          xl: '250px'
        }}
        fontWeight={ 700 }
        css={{
          color: 'white',
          '-webkit-text-fill-color': 'transparent',
          '-webkit-text-stroke-width': '1px',
          '-webkit-text-stroke-color': 'white'
        }}
      >
        { props.heading }
      </Heading>
    </Flex>
  )
}
