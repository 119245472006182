import * as React from "react";
import {
  Box
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
  position?: "relative" | "absolute" | undefined
  top?: object | string | number | undefined
  left?: object | string | number | undefined
  h?: object | string | undefined
  background?: object | string | undefined
  padding?: object | string | undefined
  transform?: string | undefined
  zIndex?: string | number | undefined
}


export const SectionWrapper: React.FC<Props> = ( props ) => {
  return (
    <Box
      as="section"
      position={ props.position ? props.position : 'relative' }
      top={ props.position === "absolute" ? props.top : '0' }
      left={ props.position === "absolute" ? props.left : '0' }
      w="100%"
      h={ props.h }
      background={ props.background }
      padding={ props.padding }
      transform={ props.transform }
      zIndex={ props.zIndex }
    >
      { props.children }
    </Box>
  )
}
