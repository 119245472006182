import * as React from "react";
import {
  Flex,
  Heading
} from "@chakra-ui/react";


export const Header: React.FC = () => {
  return (
    <Flex
      as="header"
      justifyContent="center"
      alignItems="center"
      h="100%"
      textAlign="center"
    >
      <Heading
        as="h2"
        m="0"
        color="white"
        fontSize="16px"
        fontWeight={ 400 }
        letterSpacing="5px"
        textTransform="uppercase"
      >
        We're creating something special
      </Heading>
    </Flex>
  )
}
