import * as React from "react";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { Earth } from "../components/Earth";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Ticker } from "../components/Ticker";

import {
  SectionWrapper
} from "../components/Section";


const IndexPage = () => {
  return (
    <Layout>
      <Head
        title="Coming Soon"
      />
      
      <SectionWrapper
        position="absolute"
        top="0"
        left="0"
        h="20%"
        background="transparent"
      >
        <Header />
      </SectionWrapper>

      <SectionWrapper
        position="absolute"
        top={{
          base: '40%',
          lg: '30%'
        }}
        left="0"
        h={{
          base: '30%',
          lg: '40%',
          xl: '30%'
        }}
        background="transparent"
        zIndex="10"
      >
        <Ticker
          itemWidth={{
            base: 600,
            sm: 800,
            md: 1200,
            lg: 1800,
            xl: 2000
          }}
          template="title"
          nodes={[
            {
              heading: 'Viguiliouk Law'
            },
            {
              heading: 'Viguiliouk Law'
            }
          ]}
        />
      </SectionWrapper>

      <SectionWrapper
        position="absolute"
        top={{
          base: '50%',
          lg: '60%',
          xl: '50%'
        }}
        left="0"
        h={{
          base: '50%',
          lg: '40%',
          xl: '50%'
        }}
        zIndex="10"
      >
        <Footer />
      </SectionWrapper>

      <SectionWrapper
        position="absolute"
        top="50%"
        left="50%"
        h="60%"
        transform="translate( -50%, -50% )"
      >
        <Canvas>
          <Suspense fallback={ <></> }>
            <Earth />
          </Suspense>
        </Canvas>
      </SectionWrapper>
    </Layout>
  )
}


export default IndexPage;
