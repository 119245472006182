import * as React from "react";
import {
  Flex,
  Heading,
  IconButton,
  Link,
  Stack
} from "@chakra-ui/react";

import {
  Mail,
  Phone
} from "../ScalableVectorGraphics";


export const Footer: React.FC = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        h="40%"
      >
        <Heading
          as="h3"
          mr="-20px"
          color="white"
          fontSize={{
            base: '20px',
            lg: '36px'
          }}
          fontWeight={ 100 }
          letterSpacing="20px"
          textAlign="center"
          textTransform="uppercase"
        >
          Coming Soon
        </Heading>
        <Stack
          direction="row"
          justify="center"
          spacing="40px"
        >
          <IconButton
            aria-label="Email us"
            as={ Link }
            href="mailto:viguiliouk@vglk.ca"
            isExternal
            icon={ <Mail /> }
            minW="40px"
            w="40px"
            h="40px"
            bg="transparent"
            border="1px"
            borderRadius="50%"
            borderColor="white"
            color="white"
            transition="all 0.3s ease-in-out"
            _hover={{
              bg: "white",
              border: "1px",
              borderRadius: "50%",
              borderColor: "white",
              color: "primary.900"
            }}
          />
          <IconButton
            aria-label="Call us"
            as={ Link }
            href="tel:+14168289576"
            isExternal
            icon={ <Phone /> }
            minW="40px"
            w="40px"
            h="40px"
            bg="transparent"
            border="1px"
            borderRadius="50%"
            borderColor="white"
            color="white"
            transition="all 0.3s ease-in-out"
            _hover={{
              bg: "white",
              border: "1px",
              borderRadius: "50%",
              borderColor: "white",
              color: "primary.900"
            }}
          />
        </Stack>
      </Flex>
    </Flex>
  )
}
