import * as React from "react";
import { useRef } from "react";
import * as THREE from "three";
import { TextureLoader } from "three";
import { useFrame, useLoader } from "@react-three/fiber";

import SpecMap from "../../assets/earth_specular_map.jpg";
import NormalMap from "../../assets/earth_normal_map.jpg";
import NightMap from "../../assets/earth_night_map.jpg";


export const Earth: React.FC = () => {
  const [nightMap, normalMap, specMap] = useLoader(
    TextureLoader,
    [NightMap, NormalMap, SpecMap]
  );

  const earthRef = useRef();

  useFrame( ({ clock }) => {
    const elapsedTime = clock.getElapsedTime();

    earthRef.current.rotation.y = elapsedTime / 6;
    earthRef.current.rotation.x = 0.6;
  });

  const SHADERS = {
    'atmosphere': {
      uniforms: {},
      vertexShader: [
        'varying vec3 vertexNormal;',
        'void main() {',
          'vertexNormal = normal;',
          'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
        '}'
      ].join( '\n' ),
      fragmentShader: [
        'varying vec3 vertexNormal;',
        'void main() {',
          'float intensity = pow(0.5 - dot(vertexNormal, vec3(0, 0, 1.0)), 2.0);',
          'gl_FragColor = vec4(0.3, 0.6, 1.0, 1.0) * intensity;',
        '}'
      ].join( '\n' )
    }
  };

  return (
    <>
      <pointLight
        color="#A6A6A6"
        position={ [2, 0, 7] }
        intensity={ 1.4 }
      />
      <pointLight
        color="#C1C1C1"
        position={ [15, 0, 7] }
        intensity={ 1.4 }
      />
      <pointLight
        color="#C1C1C1"
        position={ [20, 30, 30] }
        intensity={ 1.4 }
      />
      <mesh
        ref={ earthRef }
        position={ [0, 0, 3] }
      >
        <sphereGeometry args={ [1, 1024, 1024] } />
        <meshPhongMaterial specularMap={ specMap } />
        <meshStandardMaterial
          map={ nightMap }
          normalMap={ normalMap }
          metalness={ 0.4 }
          roughness={ 0.7 }
        />
      </mesh>
    </>
  )
}
